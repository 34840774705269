<template>
  <nav class="tabs" :class="wrapperClass">
    <button
      class="tabs__item"
      type="button"
      v-for="tab in tabs"
      :ref="tab.value"
      :key="tab.title"
      :class="[
        { tabs__item_active: tab.value === currentTab },
        tab.value === currentTab && tabActiveClass ? tabActiveClass : '',
        tabClass,
      ]"
      :disabled="tab.disabled || false"
      @click="handleClick(tab.value)"
    >
      <SvgIcon :icon="tab.icon" class="tabs__item__icon mr-2 text-xs" />
      <span class="tabs__item__content text-sm" v-html="tab.title"></span>
    </button>

    <!-- <div
      class="tabs__active-line"
      :class="lineClass"
      :style="{ width: `${activeLineWidth}px`, transform: `translateX(${activeLineOffset}px)` }"
    /> -->
  </nav>
</template>

<script>
export default {
  name: "TabsHorizontal",
  props: {
    currentTab: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    updated: {
      type: [Boolean, String, Array],
      default: undefined,
    },
    wrapperClass: {
      type: String,
      required: false,
    },
    tabClass: {
      type: String,
      required: false,
    },
    tabActiveClass: {
      type: String,
      required: false,
    },
    lineClass: {
      type: String,
      required: false,
    },
  },
  watch: {
    currentTab(newCurrentTab) {
      if (this.newTab === newCurrentTab) return;
      this.moveActiveLine(newCurrentTab);
      this.newTab = newCurrentTab;
    },
    updated() {
      this.moveActiveLine(this.currentTab);
    },
  },
  data: () => ({
    activeLineWidth: 0,
    activeLineOffset: 0,
    newTab: "",
  }),
  methods: {
    handleClick(value) {
      this.$emit("onClick", value);
      this.moveActiveLine(value);
      this.newTab = value;
    },
    moveActiveLine(newValue) {
      if (!this.currentTab) return;
      if (!this.$refs || !this.$refs[newValue] || !this.$refs[newValue][0]) return;
      const element = this.$refs[newValue][0];
      this.activeLineWidth = element.clientWidth;
      this.activeLineOffset = element.offsetLeft;
    },
  },
  mounted() {
    this.moveActiveLine(this.currentTab);
    this.newTab = this.currentTab;
  },
};
</script>

<style>
.tabs {
  position: relative;
  margin: 0 auto;
  top: 1px;
}

.tabs__item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  padding: 10px;
  padding-bottom: 8px;
  font-size: 16px;
  letter-spacing: 0.8px;
  text-decoration: none;
  border: 1px solid;
  border-bottom-width: 0;
  @apply border-gray-dark text-gray-darkest rounded-t-lg;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s;
}

.tabs__item_active {
  color: black;
  @apply border-blue;
}
.tabs__item_active .tabs__item__icon,
.tabs__item_active .tabs__item__content {
  @apply text-blue;
}
.tabs__item:hover {
  border-bottom-width: 2px;
  color: black;
}

.tabs__item:focus {
  outline: none;
}

.tabs__item:first-child {
  margin-left: 0;
}

.tabs__item:last-child {
  margin-right: 0;
}

.tabs__active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: black;
  transition: transform 0.4s ease, width 0.4s ease;
}
</style>
