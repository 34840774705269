export function getSignatureImage({
  text,
  fontFace = "AgreementSignature",
  width = 414,
  height = 104,
  fontSize = 16,
}) {
  const tempCanvas = document.createElement("canvas");
  const multiplier = 20;
  const improvedWidth = width * multiplier;
  const improvedHeight = height * multiplier;
  const improvedFontSize = (fontSize * multiplier) / 6;
  tempCanvas.width = improvedWidth;
  tempCanvas.height = improvedHeight;
  const ctx = tempCanvas.getContext("2d");
  ctx.font = `normal ${improvedFontSize}px ${fontFace}`;
  ctx.textAlign = `center`;
  ctx.moveTo(improvedWidth / 2, improvedHeight / 2);
  ctx.fillText(text, improvedWidth / 2, improvedHeight / 2 + 4);

  const result = tempCanvas.toDataURL("image/png", 1);
  return result;
}

export function getTextSignatureImage ( {
  text,
  fontFace = "AgreementSignature",
  width = 414,
  height = 104,
  fontSize = 36
}) {
  const tempCanvas = document.createElement("canvas");
  const multiplier = 1;
  const improvedWidth = width * multiplier;
  const improvedHeight = height * multiplier;
  const improvedFontSize = (fontSize * multiplier);
  tempCanvas.width = improvedWidth;
  tempCanvas.height = improvedHeight;
  const ctx = tempCanvas.getContext("2d");
  ctx.font = `normal ${improvedFontSize}px ${fontFace}`;
  ctx.textAlign = `center`;
  ctx.moveTo(improvedWidth / 2, improvedHeight / 2);
  ctx.fillText(text, improvedWidth / 2, improvedHeight / 2 + 4);
  const result = tempCanvas.toDataURL("image/png", 1);
  return result;
}

// https://stackoverflow.com/a/45873660
function _trimCanvas(ctx) {
  // removes transparent edges
  let x, y, w, h, top, left, right, bottom, data, idx1, idx2, found, imgData;
  w = ctx.canvas.width;
  h = ctx.canvas.height;
  if (!w && !h) {
    return false;
  }
  imgData = ctx.getImageData(0, 0, w, h);
  data = new Uint32Array(imgData.data.buffer);
  idx1 = 0;
  idx2 = w * h - 1;
  found = false;
  // search from top and bottom to find first rows containing a non transparent pixel.
  for (y = 0; y < h && !found; y += 1) {
    for (x = 0; x < w; x += 1) {
      if (data[idx1++] && !top) {
        top = y + 1;
        if (bottom) {
          // top and bottom found then stop the search
          found = true;
          break;
        }
      }
      if (data[idx2--] && !bottom) {
        bottom = h - y - 1;
        if (top) {
          // top and bottom found then stop the search
          found = true;
          break;
        }
      }
    }
    if (y > h - y && !top && !bottom) {
      return false;
    } // image is completely blank so do nothing
  }
  top -= 1; // correct top
  found = false;
  // search from left and right to find first column containing a non transparent pixel.
  for (x = 0; x < w && !found; x += 1) {
    idx1 = top * w + x;
    idx2 = top * w + (w - x - 1);
    for (y = top; y <= bottom; y += 1) {
      if (data[idx1] && !left) {
        left = x + 1;
        if (right) {
          // if left and right found then stop the search
          found = true;
          break;
        }
      }
      if (data[idx2] && !right) {
        right = w - x - 1;
        if (left) {
          // if left and right found then stop the search
          found = true;
          break;
        }
      }
      idx1 += w;
      idx2 += w;
    }
  }
  left -= 1; // correct left
  if (w === right - left + 1 && h === bottom - top + 1) {
    return true;
  } // no need to crop if no change in size
  w = right - left + 1;
  h = bottom - top + 1;
  ctx.canvas.width = w;
  ctx.canvas.height = h;
  ctx.putImageData(imgData, -left, -top);
  return ctx;
}
