<template>
  <img :src="shape" alt="Square Stamp" />
</template>

<script>
import { addRoundedRect, getVerticalText } from "@/services/stampGenerator.js";
export default {
  name: "StampImageSquare",
  props: {
    text: {
      type: String,
      default: "",
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    shape(newVal) {
      this.$emit("change", newVal);
    },
  },
  computed: {
    shape() {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const dims = 150;
      canvas.width = dims;
      canvas.height = dims;
      addRoundedRect({ ctx, x: 1, y: 1, width: dims - 2, height: dims - 2, radius: 20, lineWidth: 2 });
      let verticalTextConfig = { dims, xy: 0 };
      if (this.text.length > 0) {
        const canvasVerticalText = getVerticalText({
          text: this.text,
          isHorizontal: this.isHorizontal,
        });
        ctx.drawImage(
          canvasVerticalText,
          verticalTextConfig.xy,
          verticalTextConfig.xy,
          verticalTextConfig.dims,
          verticalTextConfig.dims
        );
      }
      return canvas.toDataURL();
    },
  },
};
</script>

<style scoped></style>
