<template>
  <div @click.capture="onClickItem" class="addible-item-w h-full relative" :class="`addible-${addible.id}`">
    <!-- sign_date -->
    <span v-if="!addible.needAction && addible.type === 'sign_date'" class="block" :style="{ ...zoomScaleStyle }">{{
      todayDate
    }}</span>

    <!-- name -->
    <span v-else-if="!addible.needAction && addible.type === 'name'" class="block" :style="{ ...zoomScaleStyle }">{{
      casePersonGet.name
    }}</span>

    <!-- email -->
    <span v-else-if="!addible.needAction && addible.type === 'email'" class="block" :style="{ ...zoomScaleStyle }">{{
      casePersonGet.email
    }}</span>

    <!-- signature -->
    <img
      v-else-if="!addible.needAction && addible.type === 'signature' && signaturePreviewUrlGet"
      :src="signaturePreviewUrlGet"
      :style="{ width: `${signatureWidth}px`, ...zoomScaleStyle }"
      alt=""
      class="block cursor-pointer"
    />

    <!-- stamp -->
    <img
      v-else-if="!addible.needAction && addible.type === 'stamp' && stampImageDataUrlGet"
      :src="stampImageDataUrlGet"
      :style="{ width: `${widthRatios.stamp * pageWidth}px`, ...zoomScaleStyle }"
      alt=""
      class="block cursor-pointer"
    />

    <!-- initial -->
    <img
      v-else-if="!addible.needAction && addible.type === 'initial' && initialImageDataUrlGet"
      :src="initialImageDataUrlGet"
      :style="{ width: `${widthRatios.initial * pageWidth}px`, ...zoomScaleStyle }"
      alt=""
      class="block cursor-pointer"
    />

    <!-- default addible -->
    <AddibleOverlayItem :style="{ ...zoomScaleStyle }" v-else class="addible-item h-full relative" :addible="addible" />

    <template v-if="isOpen" class="cursor-default">
      <InitialMaker
        v-if="addible.type === 'initial'"
        :addible="addible"
        @closed="
          isOpen = false;
          isModalOpenUpdate(false);
        "
      />
      <SignatureMaker
        v-if="addible.type === 'signature'"
        :addible="addible"
        @closed="
          isOpen = false;
          isModalOpenUpdate(false);
        "
      />
      <StampMaker
        v-if="addible.type === 'stamp'"
        :addible="addible"
        @closed="
          isOpen = false;
          isModalOpenUpdate(false);
        "
      />
    </template>
  </div>
</template>

<script>
import InitialMaker from "@/components/ProveId/InitialMaker.vue";
import SignatureMaker from "@/components/ProveId/SignatureMaker.vue";
import StampMaker from "@/components/ProveId/StampMaker.vue";
import AddibleOverlayItem from "@/components/Addible/AddibleOverlayItem.vue";
import { formatDate } from "@/services/helpers";
import { mapActions, mapGetters } from "vuex";
import { availableAddiblesByType } from "@/constants/addible";

export default {
  name: "AddibleOverlayItemSign",
  components: { AddibleOverlayItem, InitialMaker, SignatureMaker, StampMaker },
  data: () => ({ isOpen: false }),
  props: {
    pageWidth: {
      type: Number,
      required: true,
    },
    addibleId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("documentViewer", ["zoomAmountGet", "isModalOpenGet"]),
    ...mapGetters("proveId", [
      "casePersonGet",
      "stampImageDataUrlGet",
      "stampIncludeDateWithNameGet",
      "stampFullNameGet",
      "signatureTypeGet",
      "signatureTextGet",
      "signatureTextDataUrlGet",
      "signatureDrawDataUrlGet",
      "signatureImageDataUrlGet",
      "signaturePhoneNumberGet",
      "initialImageDataUrlGet",
      "signaturePreviewUrlGet",
    ]),
    ...mapGetters("doc", ["allAddiblesGet"]),
    addible() {
      return this.allAddiblesGet.find((item) => item.id === this.addibleId);
    },
    todayDate ()
    {
      return formatDate(new Date(), "MM/dd/yyyy");
    },
    widthRatios() {
      return {
        signature: 0.16,
        stamp: 0.125,
        initial: 0.032,
      };
    },
    zoomScaleStyle() {
      return { transform: `scale(${this.zoomAmountGet / 100})` };
    },
    signatureWidth () {
      const isTextSignature =
        this.signatureTextDataUrlGet != '' &&
        this.signatureDrawDataUrlGet == '' &&
        this.signatureImageDataUrlGet == '' &&
        this.signaturePhoneNumberGet == '';
      return isTextSignature ? this.widthRatios.signature * this.pageWidth * 1.4 : this.widthRatios.signature * this.pageWidth;
    }
  },
  methods: {
    ...mapActions("proveId", ["fetchSignaturePreview"]),
    ...mapActions("documentViewer", ["isModalOpenUpdate"]),
    ...mapActions("doc", ["addibleUpdate"]),
    onClickItem() {
      if (this.isModalOpenGet) {
        return;
      }
      if (!availableAddiblesByType[this.addible.type].hasAction) {
        return;
      }

      if (!this.addible.needAction) {
        this.setNeedAction(true);
        return;
      }

      if (this.previousActionPerformed()) {
        this.setNeedAction(false);
        return;
      }

      this.isModalOpenUpdate(true);
      this.isOpen = true;
    },
    setNeedAction(needAction = false) {
      const updatedAddible = { ...this.addible, needAction };
      this.addibleUpdate(updatedAddible);
    },
    previousActionPerformed() {
      const type = this.addible.type;
      const similarAddibles = this.allAddiblesGet.filter((item) => item.type === type);
      return similarAddibles.some((item) => item.needAction === false);
    },
  },
};
</script>

<style scoped>
.addible-item-w {
  scroll-margin-top: 180px;
}
</style>
