<template>
  <div>
    <div class="flex flex-col h-screen w-screen items-center justify-center">
      <t-card class="border-2 border-green p-8 text-center">
        <p class="mb-6 font-medium text-gray-darkest">{{ message }}</p>
        <a class="text-gray-dark hover:text-blue font-bold cursor-pointer" :href="reviewLink">Download Signed PDF</a>
      </t-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignSuccess",
  data: () => ({ message: "Your document successfully signed." }),
  computed: {
    reviewLink() {
      return this.$route.query.reviewLink;
    },
  },
};
</script>

<style scoped></style>
