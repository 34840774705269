<template>
  <img :src="shape" alt="" />
</template>

<script>
import { addCircle, getCircleText, getVerticalText } from "@/services/stampGenerator.js";
export default {
  name: "StampImageCircular",
  props: {
    outerText: {
      type: String,
      default: "",
    },
    innerText: {
      type: String,
      default: "",
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    shape(newVal) {
      this.$emit("change", newVal);
    },
  },
  computed: {
    shape() {
      let canvas = getCircleText({ text: this.outerText, x: 100, y: 100, radius: 75 });
      const ctx = canvas.getContext("2d");
      canvas = addCircle({ canvas, radius: 99, lineWidth: 2 });

      let verticalTextConfig = { dims: 156, xy: 24 };
      if (this.outerText.length > 0) {
        verticalTextConfig = { dims: 104, xy: 48 };
        canvas = addCircle({ canvas, radius: 66, lineWidth: 1 });
      }
      if (this.innerText.length > 0) {
        const canvasVerticalText = getVerticalText({
          text: this.innerText,
          isHorizontal: this.isHorizontal,
        });
        ctx.drawImage(
          canvasVerticalText,
          verticalTextConfig.xy,
          verticalTextConfig.xy,
          verticalTextConfig.dims,
          verticalTextConfig.dims
        );
      }
      return canvas.toDataURL();
    },
  },
};
</script>

<style scoped></style>
