<template>
  <div>
    <div v-if="currentState === 'not-started'">
      <label :for="`id_phone_number_${_uid}`" class="label mb-4 block w-1/2">
        <h4 class="mb-1">Phone Number</h4>

        <div class="flex">
          <t-input
            type="tel"
            @change="signaturePhoneNumberUpdate"
            :id="`id_phone_number_${_uid}`"
            v-model="phoneNumber"
            placeholder="e.g. +1 888 888 8888"
          ></t-input>

          <t-button @click="_submitVerifyRequest" class="ml-1" variant="success">
            <SvgIcon icon="checked" />
          </t-button>
        </div>
      </label>
    </div>

    <template v-if="currentState === 'waiting'">
      <p class="text-sm">Please wait...</p>
    </template>

    <template v-if="currentState === 'error'">
      <p class="text-sm text-red mb-4">{{ error }}</p>
      <t-button variant="info">Try again</t-button>
    </template>

    <template v-if="currentState === 'request-sent'">
      <p class="text-sm mb-1">A code has sent to your number.</p>
      <label :for="`id_code_${_uid}`" class="label mb-4 block w-1/2">
        <h4 class="mb-1">Code</h4>

        <div class="flex">
          <t-input
            type="tel"
            @change="signatureVerifyCodeUpdate"
            :id="`id_code_${_uid}`"
            v-model="code"
            placeholder="Enter your verification code"
          ></t-input>

          <t-button @click="_submitVerifyAnswer" class="ml-1" variant="success">
            <SvgIcon icon="checked" />
          </t-button>
        </div>
      </label>
    </template>

    <template v-if="currentState === 'verified'">
      <p class="text-md">
        Your phone number <code>({{ phoneNumber }})</code> successfully <b class="text-green">verified</b>.
      </p>
    </template>

    <p v-if="error" class="text-sm text-red">{{ error }}</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "VerifyMobile",
  data: () => ({ phoneNumber: "", code: "", currentState: "not-started", error: "" }),

  computed: {
    phoneNumberClean() {
      return this.phoneNumber.replace(/\s+/g, "");
    },
  },
  methods: {
    ...mapActions("proveId", [
      "signaturePhoneNumberUpdate",
      "signatureVerifyCodeUpdate",
      "signaturePhoneNumberVerifyRequest",
      "signaturePhoneNumberVerifyAnswer",
      "signaturePhoneNumberAcceptableTokenUpdate",
    ]),

    isValidPhoneNumber() {
      return /^\+[0-9\(\)]{3,}$/.test(this.phoneNumberClean);
    },

    _submitVerifyRequest() {
      this.currentState = "waiting";
      this.error = "";
      if (!this.isValidPhoneNumber()) {
        this.error = "Phone number is not valid. Sample: +1 888 888 8888";
        this.currentState = "not-started";
        return;
      }
      this.signaturePhoneNumberVerifyRequest({ phoneNumber: this.phoneNumber })
        .then(() => {
          this.currentState = "request-sent";
        })
        .catch((error) => {
          console.log(error);
          this.currentState = "not-started";
          this.error = error.message;
        });
    },
    _submitVerifyAnswer() {
      this.currentState = "waiting";
      this.signaturePhoneNumberVerifyAnswer({ phoneNumber: this.phoneNumber, code: this.code })
        .then((response) => {
          this.currentState = "verified";
          // note: this token is only accepted for 1 hour
          // todo: reset after 1 hour
          this.signaturePhoneNumberAcceptableTokenUpdate(response.acceptableToken);
        })
        .catch((error) => {
          console.log(error);
          this.currentState = "not-started";
          this.error = error.message;
          this.code = "";
        });
    },
  },
};
</script>

<style scoped></style>
