export function getCircleText({
  text: _text,
  x,
  y,
  radius,
  width = 200,
  height = 200,
}) {
  let text = _text.trim();
  // add end space to text for long texts which have 2+ words
  text = text.length > 10 && text.split(" ").length > 1 ? `${text} ` : text;

  const startRotationFix = 0.9;
  const startRotation = startRotationFix * -(Math.PI / 2);

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");

  let degreesPerLetter = (2 * Math.PI) / text.length;
  const fontSize = __getCircleTextFontSize(text.length);
  ctx.save();
  ctx.translate(x, y);
  ctx.rotate(startRotation);
  ctx.font = `500 ${fontSize}pt "Roboto Mono", monospace`;
  ctx.fillStyle = "#ff4757";

  for (let i = 0; i < text.length; i++) {
    ctx.save();
    ctx.translate(radius, 0);

    ctx.translate(10, -10);

    ctx.rotate(1.4);
    ctx.translate(-10, 10);

    ctx.fillText(text[i], 0, 0);
    ctx.restore();
    ctx.rotate(degreesPerLetter);
  }
  ctx.restore();
  return canvas;
}

function __getCircleTextFontSize(textLength) {
  let fontSize = 24;
  if (textLength > 10) {
    fontSize = 20;
  }
  if (textLength > 18) {
    fontSize = 16;
  }
  if (textLength > 30) {
    fontSize = 14;
  }
  if (textLength > 40) {
    fontSize = 10;
  }
  return fontSize;
}

export function addCircle({ canvas, radius, lineWidth = 1 }) {
  const ctx = canvas.getContext("2d");
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = "#ff4757";
  ctx.beginPath();
  ctx.arc(100, 100, radius, 0, 2 * Math.PI);
  ctx.stroke();
  return canvas;
}

export function addRoundedRect({
  ctx,
  x,
  y,
  width,
  height,
  radius,
  lineWidth = 1,
}) {
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = "#ff4757";
  ctx.beginPath();
  ctx.moveTo(x, y + radius);
  ctx.lineTo(x, y + height - radius);
  ctx.arcTo(x, y + height, x + radius, y + height, radius);
  ctx.lineTo(x + width - radius, y + height);
  ctx.arcTo(x + width, y + height, x + width, y + height - radius, radius);
  ctx.lineTo(x + width, y + radius);
  ctx.arcTo(x + width, y, x + width - radius, y, radius);
  ctx.lineTo(x + radius, y);
  ctx.arcTo(x, y, x, y + radius, radius);
  ctx.stroke();
}

export function getVerticalText({ text, isHorizontal = false }) {
  const areaWidth = 200;
  const areaHeight = 200;
  const canvas = document.createElement("canvas");
  canvas.width = areaWidth;
  canvas.height = areaHeight;

  const ctx = canvas.getContext("2d");
  ctx.fillStyle = "#ff4757";
  ctx.textAlign = "start";
  ctx.textBaseline = "top";
  const config = __getVerticalTextConfig(text.length);
  ctx.font = `500 ${config.fontSize}pt "Roboto Mono", monospace`;
  const charWidth = ctx.measureText("X").width;
  const charHeight = __getTextHeight({
    text: "X",
    fontSize: config.fontSize,
    fontWeight: 500,
    fontFamily: "Roboto Mono",
  });

  let i = 0;

  if (isHorizontal) {
    for (let col = 0; col < config.cols; col++) {
      for (let row = 0; row < config.rows; row++) {
        _drawHorizontal({ col, row, char: text[i] });
      }
    }
  } else if (!isHorizontal) {
    const reversedCols = true;
    if (reversedCols) {
      for (let col = config.cols - 1; col >= 0; col--) {
        for (let row = 0; row < config.rows; row++) {
          _drawVertical({ col, row, char: text[i] });
        }
      }
    }
  }

  function _drawHorizontal({ col, row, char }) {
    // configs are based vertical. switch it here
    const newCol = row;
    const newRow = col;
    const newCols = config.rows;
    const newRows = config.cols;

    if (i < text.length) {
      const xGap = (areaWidth - newCols * charWidth) / newCols / 2;
      const yGap =
        (areaHeight - Math.min(areaHeight, newRows * charHeight)) / newRows / 2;
      const x = newCol * charWidth + xGap * (2 * newCol + 1);
      const y = newRow * charHeight + yGap * (2 * newRow + 1);
      ctx.fillText(char, x, y);
      i++;
    }
  }

  function _drawVertical({ col, row, char }) {
    if (i < text.length) {
      const xGap = (areaWidth - config.cols * charWidth) / config.cols / 2;
      const yGap =
        (areaHeight - Math.min(areaHeight, config.rows * charHeight)) /
        config.rows /
        2;
      const x = col * charWidth + xGap * (2 * col + 1);
      const y = row * charHeight + yGap * (2 * row + 1);
      ctx.fillText(char, x, y);
      i++;
    }
  }

  return canvas;
}

function __getVerticalTextConfig(textLength) {
  // review: this can be written based on horizontal-first approach easier & without exact numbers
  const configs = [
    { from: 0, to: 1, cols: 1, rows: 1, fontSize: 72 },
    { from: 2, to: 2, cols: 1, rows: 2, fontSize: 60 },
    { from: 3, to: 3, cols: 1, rows: 3, fontSize: 44 },
    { from: 4, to: 4, cols: 2, rows: 2, fontSize: 60 },
    { from: 5, to: 6, cols: 2, rows: 3, fontSize: 44 },
    { from: 7, to: 9, cols: 3, rows: 3, fontSize: 44 },
    { from: 10, to: 12, cols: 3, rows: 4, fontSize: 36 },
    { from: 13, to: 16, cols: 4, rows: 4, fontSize: 36 },
    { from: 17, to: 20, cols: 4, rows: 5, fontSize: 28 },
    { from: 21, to: 25, cols: 5, rows: 5, fontSize: 24 },
    { from: 26, to: 30, cols: 5, rows: 6, fontSize: 22 },
    { from: 31, to: 36, cols: 6, rows: 6, fontSize: 20 },
    { from: 37, to: 42, cols: 6, rows: 7, fontSize: 18 },
    { from: 43, to: 49, cols: 7, rows: 7, fontSize: 16 },
    { from: 50, to: 56, cols: 7, rows: 8, fontSize: 16 },
    { from: 57, to: 64, cols: 8, rows: 8, fontSize: 16 },
    { from: 65, to: 72, cols: 8, rows: 9, fontSize: 16 },
    { from: 73, to: 81, cols: 9, rows: 9, fontSize: 16 },
    { from: 82, to: Infinity, cols: 9, rows: 10, fontSize: 15 },
  ];
  const targetPosision = configs.find((config) => {
    return textLength >= config.from && textLength <= config.to;
  });
  return targetPosision;
}

function __getTextHeight({ text, fontFamily, fontWeight, fontSize }) {
  let div = document.createElement("div");
  div.innerHTML = text;
  div.style.position = "absolute";
  div.style.top = "-9999px";
  div.style.left = "-9999px";
  div.style.fontFamily = fontFamily;
  div.style.lineHeight = 1;
  div.style.fontWeight = fontWeight;
  div.style.fontSize = fontSize + "pt";
  document.body.appendChild(div);
  let height = div.offsetHeight;
  document.body.removeChild(div);
  return height;
}
