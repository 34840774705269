<template>
  <div>
    <!-- note: every doc has it's own v-for. we may have more than one doc -->
    <div v-for="(pageNumber, pageIndex) in pagesCount" :key="pageNumber">
      <portal :to="overlayPortalName(doc, pageNumber)" slim>
        <div
          v-for="addible in pageAddibles[pageIndex]"
          :key="addible.id"
          class="absolute"
          :style="{ left: `${addible.x}px`, top: `${addible.y}px`, width: '220px', height: '110px' }"
        >
          <AddibleOverlayItemSign :pageWidth="getPageWidth(pageNumber)" :addibleId="addible.id" />
        </div>
      </portal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddibleOverlayItemSign from "@/components/Addible/AddibleOverlayItemSign.vue";
import { getRealDimensions } from "@/services/addiblePosisioning.js";
import windowResizeListener from "@/mixins/windowResizeListener.js";
import { bus } from "@/main.js";

export default {
  name: "AddiblesOverlaySign",
  mixins: [windowResizeListener],
  components: { AddibleOverlayItemSign },
  props: {
    pagesCount: {
      type: Number,
      default: 0,
    },
    doc: {
      type: Object,
      required: true,
    },
    docOrder: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    activeAddible: null,
    localDocAddibles: [],
    openModuleType: null,
    pageWidthList: [],
  }),
  computed: {
    ...mapGetters("editor", ["activeAddibleGet"]),
    ...mapGetters("doc", ["docAddiblesGet"]),
    ...mapGetters("documentViewer", ["zoomAmountGet"]),
    pageAddibles() {
      let result = [];
      for (let docPageNumber = 1; docPageNumber <= this.doc.pagesCount; docPageNumber++) {
        const pageAddibles = this.localDocAddibles.filter((addible) => addible.page === docPageNumber);
        result[docPageNumber - 1] = pageAddibles;
      }
      return result;
    },
  },
  watch: {
    zoomAmountGet() {
      this.calculatePageWidthList();
      this.onWindowResize();
    },
  },
  created() {
    bus.$on("window-resized", this.onWindowResize);
    bus.$on("addibles-updated", this.setLocalAddibles);
    this.calculatePageWidthList();
    this.setLocalAddibles();
  },
  methods: {
    ...mapActions("editor", ["activeAddibleUpdate"]),
    ...mapActions("doc", ["addibleAdd", "addibleUpdate"]),
    getPageWidth(pageNumber) {
      const pageIndex = pageNumber - 1;
      return this.pageWidthList[pageIndex] || 1000;
    },
    calculatePageWidthList() {
      const pageWidthList = [];
      for (let pageNumber = 1; pageNumber <= this.pagesCount; pageNumber++) {
        const pageIndex = pageNumber - 1;
        pageWidthList.push(this.calculatePageWidth(pageIndex));
      }
      this.pageWidthList = pageWidthList;
    },
    calculatePageWidth(pageIndex) {
      const documentViewerElement = document.querySelectorAll(".document-viewer")[this.docOrder];
      if (!documentViewerElement) {
        console.warn("documentViewerElement not available");
        return;
      }
      const pdfPagesElements = documentViewerElement.querySelectorAll(".pdf-page");
      const width = pdfPagesElements[pageIndex]?.clientWidth;
      if (!width) {
        console.warn("Page clientWidth not available.");
      }
      return width || 1000;
    },
    isModuleActive(moduleType) {
      return this.openModuleType === moduleType;
    },
    overlayPortalName(doc, pageNumber) {
      return `pdf-viewer-doc-${doc.id}-page-${pageNumber}-preview`;
    },
    onWindowResize() {
      this.setLocalAddibles();
    },
    setLocalAddibles() {
      const addibles = this.docAddiblesGet(this.doc.id);
      const wrapperElement = document.getElementsByClassName("document-previewer-barrier")[0];
      this.localDocAddibles = addibles.map((addible) =>
        getRealDimensions(addible, this.doc.pagesDimensionRatio, wrapperElement)
      );
    },
  },
};
</script>
