<template>
  <!-- signer sees this -->
  <div class="page-sign">
    <div class="px-8">
      <t-card variant="deep" class="mb-0">
        <div class="mb-2">
          <div v-if="doc">
            <div class="document-previewer-w">
              <div class="document-previewer-barrier mx-auto">
                <!-- note: this is only one pdf -->
                <DocumentViewer @pdf-rendered="onDocumentRender" :doc="doc" />
                <AddiblesOverlaySign
                  v-if="isDocumentRendered"
                  :doc="doc"
                  :docOrder="docOrder"
                  :pagesCount="doc.pagesCount"
                />
              </div>
            </div>
          </div>
        </div>
      </t-card>
    </div>
  </div>
</template>

<script>
import DocumentViewer from "@/components/DocumentViewer/DocumentViewer.vue";
import AddiblesOverlaySign from "@/components/DocumentSignView/AddiblesOverlaySign.vue";
export default {
  name: "SignDocumentCustomerView",
  components: { DocumentViewer, AddiblesOverlaySign },
  provide: {
    isPreview: true,
    shouldUpdateDocRenderedPageNumbers: true,
  },
  props: {
    docOrder: {
      type: Number,
      required: true,
    },
    doc: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ isDocumentRendered: false }),
  methods: {
    onDocumentRender({ pagesCount }) {
      this.$nextTick(() => {
        this.isDocumentRendered = true;
      });
    },
  },
};
</script>

<style scoped>
.document-previewer-barrier {
  max-width: 720px;
}
</style>
