<template>
  <t-modal ref="modal" @closed="$emit('closed')" :hideCloseButton="true" v-model="isOpen">
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap" rel="stylesheet" />

    <template #header>
      <h2 class="text-2xl font-bold">Adopt Your Stamp</h2>
      <p class="label">Adapt A Stamp To Finish This Document.</p>
    </template>

    <div>
      <div>
        <!-- creation method selection -->
        <div class="mb-8">
          <t-radio-group
            :options="[
              { key: 'create', text: 'Create' },
              { key: 'upload', text: 'Upload' },
            ]"
            value-attribute="key"
            text-attribute="text"
            v-model="creationMethod"
          ></t-radio-group>
        </div>

        <div class="mb-4">
          <!-- Upload -->
          <div v-if="creationMethod === 'upload'">
            <DropzoneUploader
              @vdropzone-file-added="onAddedFile"
              ref="myVueDropzone"
              :id="`dropzone_${_uid}`"
              :options="dropzoneOptions"
            />
          </div>

          <!-- Create -->
          <div v-else-if="creationMethod === 'create'">
            <div class="mb-8">
              <label class="flex label text-gray-dark mb-3">Text Direction</label>
              <div class="flex">
                <span class="label mr-2">Horizontal</span><t-toggle v-model="isHorizontal"></t-toggle>
              </div>
            </div>

            <div class="flex w-full gap-4">
              <div class="w-1/2">
                <label :for="`id_name_p1_${_uid}`" class="label mb-4 block">
                  <h4 class="mb-1">Name (64 + 20 Characters Max)</h4>
                  <t-input
                    placeholder="Organization name"
                    maxlength="64"
                    class="max-w-sm"
                    :id="`id_name_p1_${_uid}`"
                    v-model="nameP1"
                  ></t-input>
                </label>
              </div>
              <div class="w-1/4">
                <label :for="`id_name_p2_${_uid}`" class="label mb-4 block">
                  <h4 class="sr-only">Department name</h4>
                  <t-input
                    placeholder="Department name"
                    maxlength="20"
                    class="max-w-sm mt-7"
                    :id="`id_name_p2_${_uid}`"
                    v-model="nameP2"
                  ></t-input>
                </label>
              </div>
            </div>

            <div class="flex gap-8">
              <div>
                <t-radio
                  class="mb-2"
                  :id="`StampImageCircular_${_uid}`"
                  v-model="stampType"
                  name="stampType"
                  value="circular"
                  checked
                ></t-radio>
                <label
                  :for="`StampImageCircular_${_uid}`"
                  class="p-4 block rounded-md cursor-pointer stamp-wrapper"
                  :class="{ 'is-selected': stampType === 'circular' }"
                >
                  <StampImageCircular
                    :isHorizontal="isHorizontal"
                    class="stamp-image"
                    :outerText="nameP1"
                    :innerText="nameP2"
                    @change="(data) => onChangeStamp({ type: 'circular', data })"
                  />
                </label>
              </div>

              <div>
                <t-radio
                  class="mb-2"
                  :id="`StampImageSquare_${_uid}`"
                  v-model="stampType"
                  name="stampType"
                  value="square"
                ></t-radio>
                <label
                  :for="`StampImageSquare_${_uid}`"
                  class="p-4 block rounded-md cursor-pointer stamp-wrapper"
                  :class="{ 'is-selected': stampType === 'square' }"
                >
                  <StampImageSquare
                    :isHorizontal="isHorizontal"
                    class="stamp-image"
                    height="156"
                    :text="combinedText"
                    @change="(data) => onChangeStamp({ type: 'square', data })"
                  ></StampImageSquare>
                </label>
              </div>
            </div>
          </div>
          <!-- end tab contents -->
        </div>
      </div>

      <div class="flex justify-between">
        <div class="flex">
          <t-button variant="info" @click="step = 1" v-if="step === 2">Back</t-button>
        </div>
        <div class="flex">
          <t-button variant="danger" class="mr-2" @click="onCancel">Cancel</t-button>
          <t-button variant="info" @click="onSubmit">Adopt</t-button>
        </div>
      </div>
    </div>
  </t-modal>
</template>

<script>
import { mapActions } from "vuex";
import StampImageCircular from "./StampImageCircular.vue";
import StampImageSquare from "./StampImageSquare.vue";
import DropzoneUploader from "@/components/BaseComponents/DropzoneUploader.vue";
export default {
  name: "StampMaker",
  components: { StampImageSquare, StampImageCircular, DropzoneUploader },
  props: {
    addible: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    stampType: "circular",
    creationMethod: "create",
    isHorizontal: false,
    nameP1: "",
    nameP2: "",
    step: 1,
    isOpen: true,
    localstampImageDataUrl: "",
    dropzoneOptions: {
      // previewTemplate: '...',
      maxFiles: 1,
      createImageThumbnails: true,
      acceptedFiles: [".png", ".jpeg", ".jpg"].join(","),
      maxFilesize: 2, // MB
      autoProcessQueue: false,
      // don't upload yet
      url: "x",
    },
  }),
  computed: {
    combinedText() {
      return (this.nameP1 + this.nameP2).trim();
    },
  },

  methods: {
    ...mapActions("proveId", ["stampImageDataUrlUpdate"]),
    ...mapActions("doc", ["addibleUpdate"]),
    onAddedFile(file) {
      var reader = new FileReader();
      reader.onload = (event) => {
        if (!file.accepted) {
          this.localstampImageDataUrl = "";
          alert("The uploaded file is not valid.");
          return;
        }
        this.localstampImageDataUrl = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    onChangeStamp({ type, data }) {
      if (this.stampType === type) {
        this.localstampImageDataUrl = data;
      }
    },
    onSubmit() {
      if (this.creationMethod === "create" && !this.combinedText) {
        alert("Please write the name.");
        return;
      }
      this.setNeedAction(false);

      this.stampImageDataUrlUpdate(this.localstampImageDataUrl);
      this.$refs.modal.hide();
    },
    setNeedAction(needAction = false) {
      const updatedAddible = { ...this.addible, needAction };
      this.addibleUpdate(updatedAddible);
    },
    onCancel() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style scoped>
.stamp-image {
  width: 150px;
  height: 150px;
}
.stamp-wrapper {
  @apply border-4 border-transparent hover:border-blue-lightest;
}
.stamp-wrapper.is-selected {
  @apply border-blue;
}
</style>
