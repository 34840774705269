/**
 * @fileoverview dragscroll - scroll area by dragging
 * @version 0.0.8
 *
 * @license MIT, see http://github.com/kotky/dragscroll
 * @copyright 2015 asvd <heliosframework@gmail.com>
 */

(function (root, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    factory((root.dragscroll = {}));
  }
})(this, function (exports) {
  var _window = window;
  var _document = document;
  var mousemove = "mousemove";
  var mouseup = "mouseup";
  var mousedown = "mousedown";
  var touchmove = "touchmove";
  var touchup = "touchend";
  var touchdown = "touchstart";
  var EventListener = "EventListener";
  var addEventListener = "add" + EventListener;
  var removeEventListener = "remove" + EventListener;
  var newScrollX, newScrollY;

  var dragged = [];
  var reset = function (i, el) {
    for (i = 0; i < dragged.length; ) {
      el = dragged[i++];
      el = el.container || el;
      el[removeEventListener](mousedown, el.md, 0);
      el[removeEventListener](touchdown, el.md, 0);
      _window[removeEventListener](mouseup, el.mu, 0);
      _window[removeEventListener](touchup, el.mu, 0);
      _window[removeEventListener](mousemove, el.mm, 0);
      _window[removeEventListener](touchmove, el.mm, 0);
    }

    // cloning into array since HTMLCollection is updated dynamically
    dragged = [].slice.call(_document.getElementsByClassName("dragscroll"));
    for (i = 0; i < dragged.length; ) {
      (function (el, lastClientX, lastClientY, pushed, scroller, cont) {
        (cont = el.container || el)[addEventListener](
          mousedown,
          (cont.md = function (e) {
            // Return if the target (of the mousedown event) or any of it's ancestors cannot be dragged.
            // Ideally composedPath() would be used here but it's not supported by IE.
            // https://stackoverflow.com/a/39245638
            var element = e.target;
            var elements = [];
            while (element) {
              elements.push(element);
              element = element.parentNode;
            }
            for (let j = 0; j < elements.length; j++) {
              if (typeof elements[j].hasAttribute === "function" && elements[j].hasAttribute("nodrag")) {
                return true;
              }
            }

            if (!el.hasAttribute("nochilddrag") || _document.elementFromPoint(e.pageX, e.pageY) == cont) {
              pushed = 1;
              lastClientX = e.clientX;
              lastClientY = e.clientY;

              e.preventDefault();
              e.target.style.cursor = "grabbing";
            }
          }),
          0
        );

        (cont = el.container || el)[addEventListener](
          touchdown,
          (cont.td = function (e) {
            if (e.targetTouches.length == 1) {
              var touch = e.targetTouches[0];
              if (!el.hasAttribute("nochilddrag") || _document.elementFromPoint(touch.pageX, touch.pageY) == cont) {
                pushed = 1;
                lastClientX = touch.clientX;
                lastClientY = touch.clientY;

                e.preventDefault();
              }
            }
          }),
          0
        );

        _window[addEventListener](
          mouseup,
          (cont.mu = function () {
            pushed = 0;
          }),
          0
        );

        _window[addEventListener](
          touchup,
          (cont.tu = function () {
            pushed = 0;
          }),
          0
        );

        _window[addEventListener](
          mousemove,
          (cont.mm = function (e) {
            if (pushed) {
              (scroller = el.scroller || el).scrollLeft -= newScrollX = -lastClientX + (lastClientX = e.clientX);
              scroller.scrollTop -= newScrollY = -lastClientY + (lastClientY = e.clientY);
              if (el == _document.body) {
                (scroller = _document.documentElement).scrollLeft -= newScrollX;
                scroller.scrollTop -= newScrollY;
              }
            }
          }),
          0
        );

        _window[addEventListener](
          touchmove,
          (cont.tm = function (e) {
            e.preventDefault();
            if (e.targetTouches.length == 1) {
              var touch = e.targetTouches[0];
              if (pushed) {
                (scroller = el.scroller || el).scrollLeft -= newScrollX = -lastClientX + (lastClientX = touch.clientX);
                scroller.scrollTop -= newScrollY = -lastClientY + (lastClientY = touch.clientY);
                if (el == _document.body) {
                  (scroller = _document.documentElement).scrollLeft -= newScrollX;
                  scroller.scrollTop -= newScrollY;
                }
              }
            }
          }),
          0
        );
      })(dragged[i++]);
    }
  };

  if (_document.readyState == "complete") {
    reset();
  } else {
    _window[addEventListener]("load", reset, 0);
  }

  exports.reset = reset;
});
