<template>
  <t-modal ref="modal" @closed="$emit('closed')" :hideCloseButton="true" v-model="isOpen">
    <template #header>
      <h2 class="text-2xl font-bold">Adapt Your Initials</h2>
      <p class="label px-8">Confirm Your Name Initials And Signature.</p>
    </template>

    <div class="flex gap-4">
      <label :for="`id_fullname_${_uid}`" class="label mb-4 block w-1/2">
        <h4 class="mb-1">Full Name</h4>
        <t-input
          @change="initialFullNameUpdate"
          class="bg-gray-lighter"
          :id="`id_fullname_${_uid}`"
          v-model="fullName"
        ></t-input>
      </label>

      <label :for="`id_initial_${_uid}`" class="label mb-4 block">
        <h4 class="mb-1">Initials</h4>
        <SignatureImageFromText
          @change="onChangeInitialImage"
          :text="initialsText"
          class="border border-gray-dark border-opacity-50 rounded-md"
        />
      </label>
    </div>

    <template #footer>
      <div class="flex justify-end">
        <t-button variant="danger" class="mr-2" @click="onCancel">Cancel</t-button>
        <t-button variant="info" @click="onSubmitInitial">Adopt and Sign</t-button>
      </div>
    </template>
  </t-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SignatureImageFromText from "./SignatureImageFromText.vue";

export default {
  components: { SignatureImageFromText },
  name: "InitialMaker",
  data: () => ({ fullName: "", localInitialDataUrl: "", isOpen: true }),
  props: {
    addible: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("proveId", ["casePersonGet", "initialFullNameGet"]),
    initialsText() {
      const splited = this.fullName?.split(" ").filter(Boolean);
      const first = splited[0];
      const last = splited.length > 1 ? splited[splited.length - 1] : "";
      return [first, last]
        .filter(Boolean)
        .map((word) => word.substring(0, 1))
        .join("")
        .toUpperCase();
    },
  },
  methods: {
    ...mapActions("proveId", ["initialImageDataUrlUpdate", "initialFullNameUpdate"]),
    ...mapActions("doc", ["addibleUpdate"]),
    init() {
      this.fullName = this.initialFullNameGet;
      if (!this.fullName) {
        this.fullName = this.casePersonGet?.name;
      }
    },
    onChangeInitialImage(dataUrl) {
      this.localInitialDataUrl = dataUrl;
    },
    onSubmitInitial() {
      this.setNeedAction(false);

      this.initialImageDataUrlUpdate(this.localInitialDataUrl);
      this.$refs.modal.hide();
    },
    setNeedAction(needAction = false) {
      const updatedAddible = { ...this.addible, needAction };
      this.addibleUpdate(updatedAddible);
    },
    onCancel() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style scoped></style>
