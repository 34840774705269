<template>
  <t-modal ref="modal" @closed="$emit('closed')" :hideCloseButton="true" v-model="isOpen">
    <template #header>
      <h2 class="text-2xl font-bold">Adapt Your Signature</h2>
    </template>

    <div>
      <TabsHorizontal
        :tabs="tabs"
        :currentTab="currentTab"
        @onClick="handleClick"
        :wrapper-class="'vue-tabs'"
        :tab-class="'vue-tabs__item'"
        :tab-active-class="'vue-tabs__item_active'"
        :line-class="'vue-tabs__active-line'"
      >
      </TabsHorizontal>

      <div class="vue-tabs__content border border-gray-dark border-opacity-80 rounded-b-2xl p-8 mb-5">
        <!-- Type Text -->
        <div v-if="currentTab === 'type'">
          <div class="flex gap-4 flex-col">
            <label :for="`id_signature_text_${_uid}`" class="label mb-4 block">
              <h4 class="mb-1">Type Your Signature Here.</h4>
              <t-input
                  @change="signatureTextUpdate"
                  class="bg-gray-lighter"
                  :id="`id_signature_text_${_uid}`"
                  v-model="localSignatureTypeText"
                  :maxlength="25"
              ></t-input>
            </label>

            <label :for="`id_initial_${_uid}`" class="label mb-4 block">
              <h4 class="mb-1">Signature</h4>
              <SignatureImageFromTextType
                  @change="onUpdateTextSignature"
                  :text="localSignatureTypeText"
                  class="border border-gray-dark border-opacity-50 rounded-md"
              />
            </label>
          </div>
        </div>

        <!-- Draw -->
        <div v-if="currentTab === 'draw'">
          <label :for="`id_drawpad_${_uid}`" class="label mb-5 block">
            <h4 class="mb-1 uppercase">Draw Your Signature</h4>
            <DrawPad
              @input="onUpdateDrawSignature"
              :width="596"
              class="border border-gray-dark border-opacity-50 rounded-md"
            />
          </label>

          <!-- <label :for="`id_fullname_${_uid}`" class="label block">
            <h4 class="mb-1">Enter Your Name</h4>
            <t-input :id="`id_fullname_${_uid}`" v-model="nameText"></t-input>
          </label> -->
        </div>

        <!-- Image -->
        <div v-if="currentTab === 'image'">
          <DropzoneUploader
            @vdropzone-file-added="onAddedFile"
            ref="myVueDropzone"
            :id="`dropzone_${_uid}`"
            :options="dropzoneOptions"
          />
        </div>

        <!-- Mobile -->
        <div v-if="currentTab === 'mobile'">
          <VerifyMobile />
        </div>
      </div>

      <div class="flex justify-end">
        <t-button variant="danger" class="mr-2" @click="onCancel">Cancel</t-button>
        <t-button variant="info" @click="onSubmit">Adopt and Sign</t-button>
      </div>
    </div>
  </t-modal>
</template>

<script>
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
import TabsHorizontal from "@/components/BaseComponents/Tabs.vue";
import VerifyMobile from "@/components/ProveId/VerifyMobile.vue";
import DrawPad from "@/components/BaseComponents/DrawPad.vue";
import SignatureImageFromTextType from "@/components/ProveId/SignatureImageFromTextType.vue";
import DropzoneUploader from "@/components/BaseComponents/DropzoneUploader.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SignatureMaker",
  components: { DropzoneUploader, TabsHorizontal, DrawPad, VerifyMobile, SignatureImageFromTextType },
  props: {
    addible: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      dropzoneOptions: {
        // previewTemplate: '...',
        maxFiles: 1,
        createImageThumbnails: true,
        acceptedFiles: [".png"].join(","),
        maxFilesize: 2, // MB
        autoProcessQueue: false,
        // don't upload yet
        url: "x",
      },
      isOpen: true,
      tabs: [
        { title: "Type", value: "type", icon: "entering-text" },
        { title: "Draw", value: "draw", icon: "drawing" },
        { title: "Image", value: "image", icon: "picture-frame" },
        { title: "Mobile", value: "mobile", icon: "mobile-signature" },
      ],
      currentTab: "type",
      localSignatureTypeText: "",
      localSignatureTypeTextDataUrl: "",
      localSignatureImageDataUrl: "",
      localSignatureDrawDataUrl: "",
    };
  },
  mounted() {
    if (this.signatureTypeGet) {
      this.currentTab = this.signatureTypeGet;
    }
  },
  computed: {
    ...mapGetters("proveId", ["signatureTypeGet", "signaturePropGet"]),
  },
  methods: {
    ...mapActions("proveId", [
      "signatureDrawDataUrlUpdate",
      "signatureTypeUpdate",
      "signatureTextDataUrlUpdate",
      "signatureImageDataUrlUpdate",
      "signatureTextUpdate",
      "fetchSignaturePreview",
    ]),
    ...mapActions("doc", ["addibleUpdate"]),
    onAddedFile(file) {
      var reader = new FileReader();
      reader.onload = (event) => {
        if (!file.accepted) {
          this.localSignatureImageDataUrl = "";
          alert("The uploaded file is not valid.");
          return;
        }
        this.localSignatureImageDataUrl = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    onUpdateDrawSignature(dataUrl) {
      this.localSignatureDrawDataUrl = dataUrl;
    },
    onUpdateTextSignature(dataUrl) {
      this.localSignatureTypeTextDataUrl = dataUrl;
    },
    isValid() {
      return this.signaturePropGet.isValid;
    },
    onSubmit() {
      this.signatureTypeUpdate(this.currentTab);
      this.signatureTextUpdate(this.localSignatureTypeText);
      this.signatureTextDataUrlUpdate(this.localSignatureTypeTextDataUrl);
      this.signatureDrawDataUrlUpdate(this.localSignatureDrawDataUrl);
      this.signatureImageDataUrlUpdate(this.localSignatureImageDataUrl);
      if (!this.isValid()) {
        return alert("Signature is not valid.");
      }
      this.setNeedAction(false);

      this.fetchSignaturePreview();
      this.$refs.modal.hide();
    },
    setNeedAction(needAction = false) {
      const updatedAddible = { ...this.addible, needAction };
      this.addibleUpdate(updatedAddible);
    },
    onCancel() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style scoped></style>
