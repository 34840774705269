<template>
  <div class="flex justify-center items-center">
    <img v-if="initialImageDataUrl" class="initial-size block object-contain p-1" :src="initialImageDataUrl" alt="" />
    <div class="initial-size font-normal text-sm flex justify-center items-center text-gray-dark" v-else>
      Loading...
    </div>
  </div>
</template>

<script>
import { getSignatureImage } from "@/services/signatureGenerator.js";
const fontUrl1 =
  "https://res.cloudinary.com/hapifox/raw/upload/v1620897349/medx/handwriting-fonts/agreement-signature/AgreementSignature_mhwh72.ttf";
const fontName1 = "AgreementSignature";

export default {
  name: "SignatureImage",
  data: () => ({ initialImageDataUrl: "", isFontLoaded: false }),
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  watch: {
    text: {
      immediate: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    async init() {
      if (!this.isFontLoaded) {
        await this.loadFont();
        this.isFontLoaded = true;
      }

      const dataUrl = this.draw(this.text, fontName1);
      this.$emit("change", dataUrl);
    },
    draw(text, fontName) {
      const initialImageDataUrl = getSignatureImage({ text, fontFace: fontName, width: 24, height: 12 });
      this.initialImageDataUrl = initialImageDataUrl;
      return initialImageDataUrl;
    },
    loadFont() {
      var Font1 = new FontFace(fontName1, `url(${fontUrl1})`);
      return new Promise((resolve, reject) => {
        Font1.load()
          .then((loaded_face) => {
            document.fonts.add(loaded_face);
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
      });
    },
  },
};
</script>

<style scoped>
.initial-size {
  width: 96px;
  height: 48px;
}
</style>
