<template>
  <div class="is-fetching" :class="{ '---is-fetching': !fetchDone }">
    <div
      v-if="!fetchDone && !error"
      class="is-fetching-overlay fixed inset-0 bg-black bg-opacity-30 z-30 flex flex-col items-center justify-center"
    >
      <SvgIcon
        aria-label="Loading, please wait."
        icon="loading-animated"
        class="w-16 h-16 svg-full text-blue"
      ></SvgIcon>
      <p class="h3 mt-8 text-gray-darkest">Fetching document, please wait...</p>
    </div>
    <!-- loading -->
    <div v-if="isLoading" class="flex flex-col h-screen w-screen items-center justify-center">
      <SvgIcon
        aria-label="Loading, please wait."
        icon="loading-animated"
        class="w-16 h-16 svg-full text-blue"
      ></SvgIcon>
    </div>
    <!-- error -->
    <div v-else-if="error" class="flex flex-col h-screen w-screen items-center justify-center">
      <t-card class="bg-red-lighter p-8 text-center">
        <p class="mb-6 font-medium text-gray-darkest">{{ error }}</p>
        <p>
          <a class="text-gray-dark hover:text-blue font-bold" href="http://medxfactor.com/contact-us/">Contact Us</a>
        </p>
      </t-card>
    </div>

    <template v-else>
      <div class="sign-document-page">
        <!-- top bar -->
        <div class="sticky top-0 w-full z-10">
          <div class="w-full bg-blue">
            <div class="py-4 px-8 mx-auto flex items-center">
              <img
                src="https://res.cloudinary.com/hapifox/image/upload/v1619620196/medx/email-templates/logo_2x_ivnhwi.png"
                alt=""
                class="mr-6 w-14 h-14"
              />
              <div class="mr-2 flex text-white">Please make sure that all fields signed.</div>             
            </div>

            <div v-if="error">
              <p class="text-red">{{ error }}</p>
            </div>
          </div>

          <!-- toolbar (zoom,...) -->
          <div class="bg-white shadow-md">
            <div class="py-4 px-8 mx-auto flex items-center gap-2">
              <t-button
                title="Zoom In"
                class="icon-button bg-white"
                :disabled="zoomAmount >= 150"
                @click="onClickZoom(+1)"
                variant="infoIcon"
              >
                <SvgIcon class="text-blue text-xl" icon="zoom-in"></SvgIcon>
              </t-button>
              <t-button
                title="Zoom Out"
                class="icon-button bg-white"
                :disabled="zoomAmount <= 50"
                @click="onClickZoom(-1)"
                variant="infoIcon"
              >
                <SvgIcon class="text-blue text-xl" icon="zoom-out"></SvgIcon>
              </t-button>
              <!-- <t-button class="icon-button bg-white" @click="onClickRotate" variant="infoIcon">
                <SvgIcon class="text-blue text-xl" icon="rotate"></SvgIcon>
              </t-button> -->
              <!-- <t-button class="icon-button bg-white" @click="onClickHelp" variant="infoIcon">
                <SvgIcon class="text-blue text-xl" icon="help"></SvgIcon>
              </t-button> -->
            </div>
          </div>

          <button
            :disabled="!fetchDone"
            @click="onClickNext"
            class="focus-visible:outline-none focus-visible:shadow-outline next-button bg-orange text-gray-darkest absolute w-150 top-48 left-0 rounded-r-lg px-12 py-2 z-10 cursor-pointer font-medium text-lg disabled:opacity-50"
          >
            Next
          </button>
          <button 
            @click="submit"
            class="focus-visible:outline-none focus-visible:shadow-outline next-button bg-orange text-gray-darkest absolute w-150 top-55 left-0 rounded-r-lg px-12 py-2 z-10 cursor-pointer font-medium text-lg disabled:opacity-50"
          >
          <SvgIcon
            v-if="isSending"
            icon="loading-animated"
            class="p-0 m-0 flex text-blue w-5 h-5 svg-full"
          ></SvgIcon>
          <template v-else>Finish</template>
        </button>
        </div>

        <div
          v-bind="{ nodrag: isModalOpenGet }"
          class="max-w-full overflow-x-auto dragscroll"
          :class="{ 'cursor-grab': zoomAmount > 100 }"
        >
          <div class="bg-gray-lightest">
            <div class="mx-auto" :style="{ width: `${zoomAmount}%` }">
              <div v-for="(doc, index) in docListGet" :key="doc.uuid">
                <DocumentSignView :doc="doc" :docOrder="index" :zoomAmount="zoomAmount" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { availableAddibles } from "@/constants/addible.js";
import "@/lib/dragscroll.js";
import DocumentSignView from "@/components/DocumentSignView/DocumentSignView.vue";
import { mapActions, mapGetters } from "vuex";
import { makeApiUrl, pageTitle } from "../services/helpers.js";
export default {
  name: "SignDocumentPage",
  components: { DocumentSignView },
  data: () => ({ docs: [], error: "", isLoading: false, isSending: false, zoomAmount: 100, fetchDone: false }),
  computed: {
    ...mapGetters("documentViewer", ["isModalOpenGet"]),
    ...mapGetters("doc", ["docListGet", "allAddiblesGet"]),
    ...mapGetters("proveId", [
      "casePersonGet",
      "stampImageDataUrlGet",
      "stampIncludeDateWithNameGet",
      "stampFullNameGet",
      "signatureTypeGet",
      "signatureTextGet",
      "signatureTextDataUrlGet",
      "signatureDrawDataUrlGet",
      "signatureImageDataUrlGet",
      "signaturePhoneNumberGet",
      "signatureVerifyCodeGet",
      "initialImageDataUrlGet",
      "initialFullNameGet",
      "signaturePhoneNumberAcceptableTokenGet",
    ]),
    availableAddibles() {
      return availableAddibles;
    },
  },
  methods: {
    ...mapActions("signRequest", ["signRequestProcessFetch"]),
    ...mapActions("documentViewer", ["zoomAmountUpdate"]),
    ...mapActions("doc", ["docAdd", "addiblesAdd"]),
    ...mapActions("proveId", [
      "signAbilityTokenUpdate",
      "casePersonUpdate",
      "signDocuments",
      "signaturePhoneNumberAcceptableTokenUpdate",
      "signaturePhoneNumberUpdate",
    ]),
    async init() {
      this.isLoading = true;
      try {
        this.setSignAbilityToken();
        const signRequestId = this.$route.query.signRequestId;
        const accessToken = this.$route.query.accessToken;
        this.signRequestProcessFetch({
          signRequestId,
          accessToken,
        })
          .then((response) => {
            if (response.statusCode >= 400) {
              this.error = response.message;
              throw response;
            }

            const { docs, addibles, casePerson } = response;
            this.fetchDocs(docs);
            this.addiblesAdd(this.adaptAddibles(addibles));
            this.casePersonUpdate(casePerson);
          })
          .catch((error) => {
            console.log("Error in fetch process", error);
          });
      } catch (error) {
        console.log(error);
        this.error ||= "Something went wrong.";
      }
      this.isLoading = false;
    },
    async submit() {
      const { isValid, message } = this.validateFields();
      if (!isValid) {
        alert(message);
        this.onClickNext();
        return;
      }
      this.isSending = true;
      const signRequestId = this.$route.query.signRequestId;
      await this.signDocuments({ signRequestId })
        .then((response) => {
          this.$router.push({
            name: "SignSuccessPage",
            query: { reviewLink: response.reviewLink },
          });
        })
        .catch((error) => {
          this.error = error?.message || "Something went wrong.";
        });
      this.isSending = false;
    },
    validateFields() {
      let isValid = true;
      let message = "";
      const invalidAddible = this.allAddiblesGet.find((addible) => addible.needAction);
      if (invalidAddible) {
        message = `Please fill the ${invalidAddible.type} field.`;
        isValid = false;
      }
      return { isValid, message };
    },
    setSignAbilityToken() {
      const token = this.$route.query.accessToken;
      this.signAbilityTokenUpdate(token);
    },
    adaptAddibles(addibles = []) {
      return addibles.map((addible) => {
        const {
          doc_uuid: docId,
          db_id,
          db_doc_id,
          type,
          position_page: page,
          position_left: x,
          position_top: y,
        } = addible;
        return {
          docId,
          db_id,
          db_doc_id,
          type,
          page: Number(page),
          x: parseFloat(x),
          y: parseFloat(y),
          iconName: this.availableAddibles.find((item) => item.type === type).iconName,
          name: this.availableAddibles.find((item) => item.type === type).name,
        };
      });
    },
    async fetchDocs(docs = []) {
      for (let i = 0; i < docs.length; i++) {
        const { order, pdf_pages_count: pagesCount, uuid, id, updated_at: updatedAt, created_at: createdAt } = docs[i];
        const signRequestId = this.$route.query.signRequestId;
        const accessToken = this.$route.query.accessToken;
        const pdf_url = makeApiUrl(`/unsigned-document-file/${signRequestId}/${uuid}?accessToken=${accessToken}`);
        const blob = await fetch(pdf_url).then((response) => response.blob());
        const objectUrl = URL.createObjectURL(blob);
        const doc = {
          pagesCount: parseInt(pagesCount),
          db_id: id,
          id: uuid,
          updatedAt,
          createdAt,
          objectUrl,
          order,
        };
        this.docAdd(doc);
      }
      this.fetchDone = true;
    },
    onClickZoom(amount) {
      this.zoomAmount = this.zoomAmount + amount * 25;
      this.zoomAmountUpdate(this.zoomAmount);
    },
    onClickNext() {
      const addiblesSortedByPosition = this.allAddiblesGet
        .filter((addible) => addible.needAction)
        .sort(sortByY)
        .sort(sortByPage)
        .sort(sortByDocOrder.bind(this));

      const selectedAddible = addiblesSortedByPosition[0];
      if (!selectedAddible) {
        alert("All done, you can now sign the document.");
        return;
      }

      const addibleEl = document.querySelector(`.addible-${selectedAddible.id}`);
      addibleEl.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      function sortByPage(a, b) {
        return a.page - b.page;
      }
      function sortByY(a, b) {
        return a.y - b.y;
      }
      function sortByDocOrder(a, b) {
        const docA = this.docListGet.find((doc) => doc.id === a.docId);
        const docB = this.docListGet.find((doc) => doc.id === b.docId);
        return docA.order - docB.order;
      }
    },
  },
  created() {
    document.title = pageTitle("Sign Document");
    this.init();
  },
};
</script>

<style scoped>
.cursor-grab {
  cursor: grab;
}
.top-55 {
  top: 15rem;
}
.w-150 {
  width: 150px;
}
</style>
